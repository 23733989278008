import { graphql } from "gatsby";
import React, { Component, useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Button, H2, H3, H4, P, Small, theme } from "@upsolve/ui";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import ImageInContext from "../components/Media/ImageInContext";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import CSSInjector from "../components/Layout/CSSInjector";
import WaveSVG from "../../static/images/illustrations/wave.svg";
import TeamPictureWall from "../components/team/TeamPictureWall";
import { CTATarget } from "../components/CTA/getCTAData";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "Team",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

const teamMemberData = [
  {
    name: "Jonathan Petts",
    imageKey: "jonathanPetts",
    title: "CEO",
    email: "jonathan@upsolve.org",
    bio: `Jonathan Petts is the CEO and co-founder of Upsolve, a platform helping low-income Americans overcome debt and rebuild their credit. 
A graduate of Penn Law with over a decade of bankruptcy expertise, his career spans clerkships with federal judges, roles at two international law firms, and consulting on bankruptcy systems for the IMF and World Bank. 
Driven by a passion for financial justice, Jonathan co-founded Upsolve to make debt relief simple and accessible for everyone. 
In 2023, he was recognized by Money as one of 50 innovators shaping America’s finances. Outside of work, he enjoys long-distance running and spending time with his family.`,
  },
  {
    name: "Ben Jackson",
    imageKey: "benJackson",
    title: "COO",
    email: "ben@upsolve.org",
    bio: `Ben Jackson co-founded Upsolve after his own experience navigating $60,000 of crippling debt and finding freedom through bankruptcy. 
That journey opened his eyes to how inaccessible and confusing the bankruptcy process was for millions of Americans who needed a fresh start. 
Motivated by the belief that everyone deserves a second chance, Ben set out to build a tool that simplifies bankruptcy, making it easier for individuals to escape debt and rebuild their financial lives. 
His personal story of overcoming financial hardship drives his mission to empower others to achieve the same freedom.`,
  },
  {
    name: "Jeff Zhou",
    imageKey: "jeff",
    title: "CTO",
    email: "jeff@upsolve.org",
  },
  {
    name: "Claire Robinson",
    imageKey: "claireRobinson",
    title: "Director of Bankruptcy",
    email: "claire@upsolve.org",
    bio: `Claire Robinson is Upsolve's Product Director, where she leads efforts to design and scale solutions that make debt relief more accessible and equitable. Before joining Upsolve, Claire led go-to-market at a fast-growing Y Combinator startup, driving a 4x revenue increase during her tenure. Earlier in her career, she worked in direct legal services, focusing on domestic violence advocacy, public defense, and plaintiff-side litigation.
Claire graduated from Stanford University with a B.A. in her self-designed major, "Community, Identity, and Access to Justice," where she explored the intersections between marginalized identity, systemic change, and the legal system. Her passion for social impact stems from her personal experiences as a gay woman and a health crisis that left her financially struggling for two years, inspiring her commitment to fighting poverty.
When Claire is not working to empower underserved communities, she can be found diving into philosophy, reading advice columns, or 50 feet in the air at the climbing gym. `,
  },
  {
    name: "Fernando Urbina",
    imageKey: "fernandoUrbina",
    title: "Chief of Staff",
    email: "fernando@upsolve.org",
    bio: `Fernando Urbina serves as the Chief of Staff at Upsolve, where he oversees key functions including administration, HR, finances, hiring, and recruiting. A graduate of Harvard University, Fernando earned his B.A. in Government with a secondary in Ethnicity, Migration, & Rights.
Before joining Upsolve, Fernando worked as a legal intern at the Michigan Immigrant Rights Center and as the Director of Outreach at ImmigrationHelp.org. Recognized for his advocacy in immigration rights and legal system reform, Fernando was named to the Latino 30 Under 30 list in 2022.
Outside of his professional endeavors, Fernando is deeply passionate about soccer. He coaches at the high school Varsity level and co-founded a youth soccer club in Arizona, where he continues to serve as President.`,
  },
  {
    name: "Juliana Simmons",
    imageKey: "julianaSimmons",
    title: "Head of Philanthropy",
    email: "juliana@upsolve.org",
    bio: `Juliana is Head of Philanthropy at Upsolve, where she leads the team's development operations. 
Prior to joining Upsolve, Juliana served as Philanthropy Manager at Mission Asset Fund, where she fundraised around financial justice initiatives for low-income and immigrant communities. 
Juliana holds a Master of Science in Public Health from Johns Hopkins University, and is driven by the understanding that financial stability is a critical social determinant of health. 
Outside of work, she enjoys camping and spending time outdoors with her children.`,
  },
  {
    name: "Zev Izenberg",
    imageKey: "zev",
    title: "Software Engineer",
    email: "zev@upsolve.org",
    bio: `Zev Izenberg is a painter and a software engineer on the Upsolve team. They make clean code and messy art. 
They are passionate about creating simple, accessible, and good-looking systems that make accessing your rights easy.`,
  },
  {
    name: "Mae Koppes",
    imageKey: "maeKoppes",
    title: "Content Manager",
    email: "mae@upsolve.org",
    bio: `Mae Koppes (she/her) is the Content Director at Upsolve, where she focuses on producing accessible and actionable content that helps empower people to overcome financial hardships. 
Mae previously worked as a copy editor and reporter for an award-winning Alaska weekly. She holds a B.S. in Sociology from Missouri State University and has certificates in editing from UCSD and POYNTER/ACES. 
Outside of work, Mae can be found reading or exploring the natural beauty of Idaho with her pup.`,
  },
  {
    name: "Zach Burghardt",
    imageKey: "zachBurghardt",
    title: "Product Designer",
    email: "zach@upsolve.org",
    bio: `Zach is a Product Designer at Upsolve, where he leverages 15 years of experience to build intuitive and impactful software and brand products. 
Prior to joining Upsolve, Zach honed his craft by designing solutions that bridge user needs with business goals, contributing to the success of numerous companies such as AngelList and Zillow. 
He was drawn to Upsolve’s mission of empowering individuals to overcome financial challenges, inspired by his passion for creating tools that make a difference in people’s lives. 
Outside of work, Zach enjoys photography, exploring innovative design trends, and spending quality time with his family.`,
  },
  {
    name: "Nick Brown",
    imageKey: "nick",
    title: "Director of Innovation",
    email: "nick@upsolve.org",
    bio: `Nick Brown is Director of Innovation at Upsolve, where he develops data insights to increase impact. Nick joined Upsolve in 2019 as its Director of Outreach and later became the organization's product manager. In that role, he helped Upsolve earn recognition as one of Time Magazine's Best Inventions of 2020. Nick holds an A.B. in Social Studies from Harvard University, where he wrote his thesis on the American access to justice crisis. During a one-year break from Upsolve, Nick conducted macroeconomic research on world-renowned investor Ray Dalio's personal research team at Bridgewater Associates. Outside of work, Nick enjoys cooking with friends and family.`,
  },
  {
    name: "Jacqueline Sadlo",
    imageKey: "jacquelineSadlo",
    title: "Paralegal, User Advocate",
    email: "jacky@upsolve.org",
    bio: `Jacqueline Sadlo is a Paralegal and User Advocate at Upsolve, where she helps individuals navigate the complexities of bankruptcy and achieve a fresh financial start. 
Since joining Upsolve in 2019, she has reviewed thousands of bankruptcy petitions, kept the team informed on regulatory changes, and worked to make legal processes more accessible for underserved communities. 
Before Upsolve, Jacqueline gained over a decade of legal experience working with government agencies, attorneys, and judges, handling complex multi-jurisdictional cases and advocating for both individuals and corporations.
She holds a Paralegal Certificate from Boston University and plans to pursue her law degree at the University of Memphis. 
Jacqueline was drawn to Upsolve’s mission because her personal experiences with poverty, single parenthood, and navigating government systems have given her a deep empathy for those struggling financially. 
Outside of work, she enjoys managing her busy household of pets, traveling across the U.S., kayaking, and creating lasting memories with her family.`,
  },
  {
    name: "Melissa Ingersoll",
    imageKey: "melissaIngersoll",
    title: "Paralegal, User Advocate",
    email: "melissa@upsolve.org",
    bio: `Melissa Ingersoll is a Paralegal and User Advocate at Upsolve, where she helps individuals navigate the bankruptcy process, reviews petitions, and ensures users have the tools and guidance they need to achieve financial stability. 
Before joining Upsolve, Melissa gained extensive legal experience at the U.S. Bankruptcy and District Courts for the Northern District of New York, where she managed case filings, assisted clients, and supported courtroom operations. 
She holds a Bachelor of Science in Criminal Justice from Saint Leo University and is certified in Public Management from Florida State University. 
Melissa was drawn to Upsolve’s mission because of her dedication to simplifying complex legal systems and helping individuals gain access to life-changing solutions. 
Outside of work, she is active in her community through local sports organizations and enjoys spending time with her family.`,
  },
];

const TeamMemberProfile = ({ teamMember, teamMemberImage }) => {
  const [visibleBio, setVisibleBio] = useState(false);
  return (
    <div className="team-member">
      <div className={`team-member__picture ${teamMemberImage ? "has-picture" : ""}`}>
        {teamMemberImage?.image?.fluid || teamMemberImage?.childImageSharp?.fluid ? (
          <ImageInContext
            alt={teamMember.name}
            fluid={teamMemberImage?.image?.fluid || teamMemberImage?.childImageSharp?.fluid}
          />
        ) : (
          <img alt={teamMember.name} src="/images/blob-attorneyHeadShot.png" />
        )}
      </div>
      {teamMember.url ? (
        <h3>
          <a href={teamMember.url}>{teamMember.name}</a>
        </h3>
      ) : (
        <h3>{teamMember.name}</h3>
      )}
      <h5>{teamMember.title}</h5>
      <div className={`team-member__bio ${visibleBio && "visible"}`}>{teamMember.bio}</div>
      {teamMember.bio && (
        <Button
          size="xs"
          buttonType="white"
          className="team-member__bio-toggle"
          onClick={() => setVisibleBio(!visibleBio)}
        >
          {!visibleBio ? "Read Bio" : "Close Bio -"}
        </Button>
      )}
    </div>
  );
};

class Team extends Component {
  state = {
    teamMembers: teamMemberData,
  };

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    const { data } = this.props;
    const { teamMembers } = this.state;
    const teamAuthors = (this.props.data.allContentfulAuthor.nodes || [])
      .filter((author) => {
        // Filter Upsolve accounts
        if (/upsolve/gi.test(author.name)) return false;
        // Filter past team members/writers or current team members that are also authors
        const pastTeamMembers = [
          "Attorney Andrea Wimmer",
          "Attorney Jonathan Petts",
          "Kristin Turner, Harvard Law Grad",
          "Rohan Pavuluri",
        ];
        if (pastTeamMembers.includes(author.name)) return false;
        return true;
      })
      .map((author) => ({
        ...author,
        bio: author?.biography?.biography,
        title: "Education & Content",
        name: author.name,
      }));

    return (
      <TopLevelLayout showPersistentCta={false}>
        <div className="index-container">
          <Helmet title="Upsolve Team" />
          <SEO
            title="Team | Upsolve"
            description="Upsolve is a team of lawyers, engineers, and judges who aim to transform legal services for low-income Americans."
            image="https://upsolve.org/images/meta-probono.png"
            url="https://upsolve.org/team/"
            jsonLD={[...teamMemberData, ...teamAuthors].map((teamMember) => ({
              "@context": "http://schema.org",
              "@type": "Person",
              description: teamMember.bio,
              email: teamMember.email,
              jobTitle: teamMember.title,
              knowsAbout: "Bankruptcy, Chapter 7, Debt",
              name: teamMember.name,
            }))}
          />
          <CSSInjector
            injectCSS={`body { background: linear-gradient(0deg, ${theme.colors.brand[100]}, ${theme.colors.brand[300]}); }`}
          />
          <Header trackingPageContext={trackingPageContext} ctaTarget={CTATarget.DEBT_TRIAGE} />

          <StyledTeamPage>
            <div className="team__header">
              <Small>Our Mission</Small>
              <H2 as="h1">Everyone Deserves Access to a Fresh Start</H2>
              <H4 as="h2">
                We're leading a movement to fight for a legal & financial system we can all access, combining direct
                services and advocacy. Using education, technology, and community, Upsolve empowers low-income and
                working-class families to access their legal rights and improve their financial situation. Partnering
                with our large base of Upsolve users, we also fight to fundamentally change a system stacked against us.
              </H4>
            </div>

            <div className="team__transition-waves">
              <WaveSVG />
              <WaveSVG />
            </div>

            <div className="team__list">
              <TeamPictureWall />
              <H2>Your Upsolve Team</H2>
              <P>
                Besides being lawyers, engineers, academics, and judges we are most importantly people that believe
                nobody should be too broke to get back on their feet.
              </P>
              <div className="team__list__team-members">
                {teamMembers.map((teamMember) => (
                  <TeamMemberProfile
                    key={teamMember.name}
                    teamMember={teamMember}
                    teamMemberImage={data[teamMember.imageKey]}
                  />
                ))}
                {/* HIDING TEAM AUTHORS to focus in on core team members */}
                {/* {teamAuthors.map((teamAuthor) => (
                  <TeamMemberProfile
                    key={teamAuthor.name}
                    teamMember={{ name: teamAuthor.name, title: "Education & Content", bio: teamAuthor?.bio }}
                    teamMemberImage={teamAuthor.image}
                  />
                ))} */}
              </div>
              {/* <P className="team__list__alumni">
                Special thanks to those who have joined us over the years as fellows: <b>Kristin Turner (Harvard Law Access to Justice Fellow)</b>; and interns: <b>Mary Gao</b>, <b>Johnny Kim</b>, <b>Anika McHayle</b>, <b>Ella Michaels</b>
              </P> */}
            </div>

            <div className="team__supporters">
              <H3 as="h2">Philanthropic & Inkind Supporters</H3>
              <P>
                We couldn't have made it this far without the great organizations below who believe in us. We're lucky
                to be supported by top firms, foundations, and institutions across the law, philanthropy, government,
                education, and technology.
              </P>
              <div>
                <div>
                  <img alt="Legal Services Corporation" src={require("../../static/images/lsc.png")} />
                </div>
                <div>
                  <img alt="Robinhood" src={require("../../static/images/robinhood.png")} />
                </div>
                <div>
                  <img alt="Schmidt Futures" src={require("../../static/images/schmidt-futures.png")} />
                </div>
                <div>
                  <img alt="Black Rock" src={require("../../static/images/blackrock.png")} />
                </div>
                <div>
                  <img alt="Susan Crown Exchange" src={require("../../static/images/susan-crown-exchange.png")} />
                </div>
                <div>
                  <img alt="Breyer Labs" src={require("../../static/images/breyer-labs.png")} />
                </div>
                <div>
                  <img
                    alt="Lawyers Trust Fund of Illinois"
                    src={require("../../static/images/lawyers-trust-illinois.png")}
                  />
                </div>
                <div>
                  <img alt="Blue Ridge Labs" src={require("../../static/images/blue-ridge-labs.png")} />
                </div>
                <div>
                  <a href="https://ffwd.org/" target="_blank" rel="noreferer noopener">
                    <img alt="Fast Forward" src={require("../../static/images/fast-forward.png")} />
                  </a>
                </div>
                <div>
                  <a href="https://www.ycombinator.com/companies/12095" target="_blank" rel="noreferer noopener">
                    <img alt="Y-Combinator" src={require("../../static/images/y-combinator.jpg")} />
                  </a>
                </div>
                <div>
                  <img alt="Yale University" src={require("../../static/images/yale.png")} />
                </div>
                <div>
                  <img alt="Princeton University" src={require("../../static/images/princeton.png")} />
                </div>
                <div>
                  <img
                    alt="Graphic Advocacy Project"
                    src={require("../../static/images/graphic-advocacy-project.png")}
                  />
                </div>
                <div>
                  <img alt="NextChapter" src={require("../../static/images/next-chapter.png")} />
                </div>
                <div>
                  <a href="https://segment.com/industry/startups/" target="_blank" rel="noreferer noopener">
                    <img alt="Segment" src={require("../../static/images/segment.png")} />
                  </a>
                </div>
                <div>
                  <a href="https://contentful.com" target="_blank" rel="noreferer noopener">
                    <img alt="Contentful" src={require("../../static/images/contentful.png")} />
                  </a>
                </div>
                <div>
                  <img alt="New Media Ventures" src={require("../../static/images/nmv.jpg")} />
                </div>
                <div>
                  <img alt="Silicon Valley Community Foundation" src={require("../../static/images/svcf.png")} />
                </div>
                <div>
                  <img alt="Khosla Ventures" src={require("../../static/images/khosla-ventures.png")} />
                </div>
              </div>
              <P className="probono">
                <b>Proud to be represented pro bono by:</b>
              </P>
              <div className="probono">
                <div>
                  <img alt="Weil" src={require("../../static/images/weil.jpg")} />
                </div>
                <div>
                  <img
                    alt="The Morrison Foerster Foundation"
                    src={require("../../static/images/morrisonfoerster.jpg")}
                  />
                </div>
              </div>
            </div>

            <div className="team__directors">
              <H3 as="h2">Board of Directors</H3>
              <div>
                <div>
                  <h3>Mark Hansen</h3>
                  <h5>Cofounder, Upsolve</h5>
                </div>
                <div>
                  <h3>Alisa Pratt</h3>
                  <h5>Bronx Community Leader</h5>
                </div>
                <div>
                  <h3>Scott Kelly</h3>
                  <h5>President, AfterPattern</h5>
                  <h5>Former ACLU Lawyer</h5>
                </div>
                <div>
                  <h3>Rohan Pavuluri</h3>
                  <h5>Board Chair</h5>
                </div>
                <div>
                  <h3>Milton Syed</h3>
                  <h5>Investor</h5>
                </div>
                <div>
                  <h3>Steve Lee</h3>
                  <h5>Former Robin Hood Foundation Managing Director, Income Security</h5>
                </div>
                <div>
                  <h3>Alex Mooradian</h3>
                  <h5>Consumer Debt Expert</h5>
                </div>
              </div>
            </div>

            <div className="team__advisors">
              <H3 as="h2">Advisory Board</H3>
              <div>
                <div>
                  <h3>Hon. Judith Fitzgerald</h3>
                  <h5>Bankruptcy Judge (1987-2013)</h5>
                </div>
                <div>
                  <h3>Professor Nick Sinai</h3>
                  <h5>U.S. Deputy CTO (2013-2014)</h5>
                </div>
                <div>
                  <h3>Professor Jim Greiner</h3>
                  <h5>Law Professor</h5>
                </div>
                <div>
                  <h3>Prof. Lois Lupica</h3>
                  <h5>University of Maine Law School</h5>
                </div>
                <div>
                  <h3>Warren Agin</h3>
                  <h5>Chapter 7 Trustee</h5>
                </div>
                <div>
                  <h3>Jan Baker</h3>
                  <h5>Retired Partner, Latham & Watkins LLP</h5>
                </div>
                <div>
                  <h3>Ron Peterson</h3>
                  <h5>
                    President, National Association
                    <br />
                    of Bankruptcy Trustees
                  </h5>
                </div>
                <div>
                  <h3>Edward J. Walters</h3>
                  <h5>CEO of Fastcase</h5>
                </div>
                <div>
                  <h3>Neil Devani</h3>
                  <h5>Early Stage Investor</h5>
                </div>
                <div>
                  <h3>Holly Allen</h3>
                  <h5>Senior Engineering Manager, Slack</h5>
                </div>
                <div>
                  <h3>Hon. Robert Gordon</h3>
                  <h5>Bankruptcy Judge (2006-2020)</h5>
                </div>
                <div>
                  <h3>Austin Smith</h3>
                  <h5>Partner, Smith Law Group LLP</h5>
                </div>
                <div>
                  <h3>Derison Puntier</h3>
                  <h5>Credit Building Leader</h5>
                </div>
                <div>
                  <h3>Ezra Berger CFP</h3>
                  <h5>Consumer Debt and Credit Expert</h5>
                </div>
                <div>
                  <h3>Miguel Willis</h3>
                  <h5>Innovator in Residence at Penn Law</h5>
                </div>
              </div>
            </div>
          </StyledTeamPage>
          <Footer bio={false} />
        </div>
      </TopLevelLayout>
    );
  }
}

const StyledTeamPage = styled.main`
  overflow-x: hidden;

  div.team__header {
    height: 440px;
    padding: 5em 1em 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 100%;
    h1,
    h2,
    h4,
    p,
    small {
      color: white;
      max-width: 960px;
      line-height: 150%;
    }
    h1 {
      margin-bottom: 0.25em;
    }
    small {
      opacity: 0.5;
    }
    h2,
    h4 {
      opacity: 0.8;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      min-height: 80vh;
      height: calc(100vh + 100px);
      justify-content: flex-start;
      h1 {
        font-size: 40px;
        line-height: 140%;
      }
      h2 {
        font-size: 18px;
      }
    }
  }
  div.team__transition-waves {
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: auto;
    width: 100%;
    max-width: 100%;
    margin: -9em 0 -3em;
    overflow: hidden;
    position: relative;
    svg {
      height: auto;
      width: 120%;
      position: relative;
    }
    svg:first-of-type {
      transform: rotate(-2deg) translateX(-5%) translateY(62%);
      path {
        fill: ${(props) => props.theme.colors.brand[500]};
      }
    }
    svg:last-of-type {
      transform: rotate(2deg) translateX(-7%);
      path {
        fill: white;
      }
    }
  }
  .team__list {
    position: relative;
    z-index: 100;
    width: 100%;
    background: white;
    padding: 0;
    margin-top: -2em;
    & > h2,
    & > p {
      max-width: 800px;
      text-align: center;
    }
    & > h2 {
      margin: 0 auto 0.25em;
      color: ${(props) => props.theme.colors.brand[500]};
    }
    & > p {
      padding: 0 1em;
      margin: 0 auto 3em;
    }
    & > div.team__list__team-members {
      width: 920px;
      margin: 0 auto;
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      grid-gap: 0.5em;
    }
    .team-member {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 320px;
      max-width: 100%;
      margin: 0 0 1em;
      &:nth-child(even) {
        position: relative;
        top: 0;
      }
      &:nth-child(odd) {
        position: relative;
        top: 0;
      }
      h3 {
        margin: 0.5em 0 0;
        text-align: center;
        a,
        a:active,
        a:visited,
        a:hover {
          color: inherit;
        }
      }
      .team-member__picture {
        height: 180px;
        min-height: 180px;
        width: 180px;
        min-width: 180px;
        border-radius: 90px;
        overflow: hidden;
        &.has-picture {
          border: 5px solid ${(props) => props.theme.colors.brand[300]};
        }
        & > img {
          width: 100%;
        }
      }
      .team-member__bio {
        overflow: hidden;
        height: 0px;
        &.visible {
          height: 100%;
          padding: 0.75em;
          margin: 0.25em 0 0.75em;
          border-radius: 18px;
          border: 2px solid ${(props) => props.theme.colors.white[500]};
          font-size: 13px;
        }
      }
    }
    .team__list__alumni {
      margin: 2em auto 3em;
      width: 100%;
      font-size: 14px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      .team-member {
        width: 100%;
        &:nth-child(even),
        &:nth-child(odd) {
          top: 0;
        }
      }
      .team__list__picture-wall {
        display: none;
      }
    }
  }
  .team__directors,
  .team__advisors,
  .team__supporters {
    background: white;
    padding: 1em 0;
    & > h2 {
      text-align: center;
      margin: 0 auto 0.5em;
      color: ${(props) => props.theme.colors.brand[500]};
    }
    & > p {
      width: 100%;
      max-width: 920px;
      padding: 0 1em;
      margin: 0 auto 1em;
      text-align: center;
      color: ${(props) => props.theme.colors.gray[900]};
      &.probono {
        color: ${(props) => props.theme.colors.brand[500]};
      }
    }
    & > div {
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;
      padding: 0 1em;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      grid-gap: 2em;
      place-self: center;
      div {
        margin: 0.5em auto 1em;
        h3,
        h5 {
          text-align: center;
        }
      }
      &.probono {
        grid-template-columns: repeat(2, minmax(240px, 1fr));
      }
    }
  }
  .team__supporters {
    & > div {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      align-items: center;
      margin: 2em auto;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        img {
          max-height: 64px;
          max-width: 100%;
        }
      }
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0.25em;
        & > div {
          padding: 0 2em;
        }
      }
    }
  }
`;

export const pageQuery = graphql`
  query TeamPage {
    allContentfulAuthor(sort: { fields: [slug], order: ASC }) {
      nodes {
        ...AuthorNode
      }
    }
    benJackson: file(base: { eq: "benJackson.png" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    claireRobinson: file(base: { eq: "claireRobinson.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dinah: file(base: { eq: "dinah.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fernandoUrbina: file(base: { eq: "fernandoUrbina.png" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jacquelineSadlo: file(base: { eq: "jacquelineSadlo.png" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jeff: file(base: { eq: "jeff.png" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jonathanPetts: file(base: { eq: "jonathanPetts.png" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    julianaSimmons: file(base: { eq: "julianaSimmons.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    maeKoppes: file(base: { eq: "maeKoppes.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mark: file(base: { eq: "mark.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    melissaIngersoll: file(base: { eq: "melissaIngersoll.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nick: file(base: { eq: "nick.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zachBurghardt: file(base: { eq: "zachBurghardt.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zev: file(base: { eq: "zev.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Team;
